const geschlechtObj = [
  { label: 'Männlich', id: 'männlich' },
  { label: 'Weiblich', id: 'weiblich' },
  { label: 'Divers', id: 'divers' },
  { label: 'Keine Angabe', id: 'none' },
];

const geschlechtObjEng = [
  { label: 'Male', id: 'male' },
  { label: 'Female', id: 'female' },
  { label: 'Various', id: 'various' },
  { label: 'None', id: 'none' },
];

const corpsFunction = [
  'Club Beauftragte für Öffentlichkeitsarbeit',
  'Club Delegierte',
  'Club Funktionsträgerin SI LEaR',
  'Club Hilfsfondsdelegierte',
  'Club Mitglied',
  'Club Präsidentin',
  'Club Präsidentin elect',
  'Club Programmassistentin',
  'Club Programmdirektorin',
  'Club Schatzmeisterin',
  'Club Schriftführerin',
  'Club stellvertretende Delegierte',
  'Club Vizepräsidentin 1',
  'Club Vizepräsidentin 2',
  'SID Advocaybeauftragte',
  'SID Clubmoderation',
  'SID Funktionsträgerin SIE',
  'SID Governeurin 1',
  'SID Governeurin 2',
  'SID Immediate Past-Präsidentin',
  'SID Kommunikationskoordinatorin',
  'SID Mitglied Extensionkomitee',
  'SID Mitglied Finanzkomitee',
  'SID Mitglied Satzungskomitee',
  'SID Mitglied Schiedsstelle',
  'SID Past-Präsidentin SID',
  'SID Past-Präsidentin SIE',
  'SID Präsidentin',
  'SID Präsidentin elect',
  'SID Programmassistentin',
  'SID Programmdirektorin',
  'SID Schatzmeisterin',
  'SID Schriftführerin',
  'SID stellvertretende Governeurin 1',
  'SID stellvertretende Governeurin 2',
  'SID stellvertretende Vertreterin Deutscher Frauenrat',
  'SID stellvertretende Vertreterin Landesfrauenrat',
  'SID Vertreterin Deutscher Frauenrat',
  'SID Vertreterin Landesfrauenrat',
  'SID Vizepräsidentin 1',
  'SID Vizepräsidentin 2',
  'SID Vizepräsidentin 3',
  'SID Vizepräsidentin 4',
  'SID Vizepräsidentin 5',
  'SID Vizepräsidentin 6',
  'SID Vizepräsidentin 7',
  'SID Vizepräsidentin 8',
  'SID Vorsitzende Extensionkomitee',
  'SID Vorsitzende Finanzkomitee',
  'SID Vorsitzende Satzungskomitee',
  'SID Vorsitzende Schiedsstelle',
  'SID Vorsitzende SI LEaR',
  'SID Soroptimist-Hilfsfonds',
  'Club Past-Präsidentin',
  'SID Social Media Communication',
];

const corpsStatuses = [
  { label: 'aktives Mitglied', id: 'aktives Mitglied' },
  { label: 'Clubfreundin', id: 'Clubfreundin' },
  { label: 'Senior-Mitglied', id: 'Senior-Mitglied' },
];

const employmentType = [
  { label: 'Keine', id: 'Keine' },
  { label: 'Angestelle/r oder Beamte/r (Vollzeit)', id: 'Angestelle/r oder Beamte/r (Vollzeit)' },
  { label: 'Angestelle/r oder Beamte/r (Teilzeit)', id: 'Angestelle/r oder Beamte/r (Teilzeit)' },
  { label: 'Praktikum', id: 'Praktikum' },
  { label: 'Freiberuflich/Selbstständig', id: 'Freiberuflich/Selbstständig' },
  { label: 'Inhaber/in', id: 'Inhaber/in' },
  { label: 'Gesellschafter/in oder Partner/in', id: 'Gesellschafter/in oder Partner/in' },
  { label: 'Vorstandsmitglied', id: 'Vorstandsmitglied' },
  { label: 'Ehrenamtlich', id: 'Ehrenamtlich' },
  { label: 'Im Ruhestand', id: 'Im Ruhestand' },
];

const activityType = [
  { label: 'Keine', id: 'Keine' },
  {
    label: 'Administration, Sachbearbeitung und Verwaltung',
    id: 'Administration, Sachbearbeitung und Verwaltung',
  },
  { label: 'Analyse und Statistik', id: 'Analyse und Statistik' },
  { label: 'Beratung / Consulting', id: 'Beratung / Consulting' },
  { label: 'Bildung und Erziehung', id: 'Bildung und Erziehung' },
  { label: 'Controlling und Planung', id: 'Controlling und Planung' },
  { label: 'Customer Service und Kundenbetreuung', id: 'Customer Service und Kundenbetreuung' },
  {
    label: 'Einkauf, Materialwirtschaft und Logistik',
    id: 'Einkauf, Materialwirtschaft und Logistik',
  },
  {
    label: 'Finanzen, Rechnungswesen und Controlling',
    id: 'Finanzen, Rechnungswesen und Controlling',
  },
  { label: 'Forschung, Lehre und Entwicklung', id: 'Forschung, Lehre und Entwicklung' },
  { label: 'Gesundheit, Medizin und Soziales', id: 'Gesundheit, Medizin und Soziales' },
  { label: 'Grafik, Design und Architektur', id: 'Grafik, Design und Architektur' },
  { label: 'IT und Softwareentwicklung', id: 'IT und Softwareentwicklung' },
  { label: 'Ingenieurwesen und technische Berufe', id: 'Ingenieurwesen und technische Berufe' },
  { label: 'Management und Unternehmensentwicklung', id: 'Management und Unternehmensentwicklung' },
  { label: 'Marketing und Werbung', id: 'Marketing und Werbung' },
  {
    label: 'PR, Öffentlichkeitsarbeit und Journalismus',
    id: 'PR, Öffentlichkeitsarbeit und Journalismus',
  },
  { label: 'Personalwesen und HR', id: 'Personalwesen und HR' },
  { label: 'Produktion und Handwerk', id: 'Produktion und Handwerk' },
  { label: 'Produktmanagement', id: 'Produktmanagement' },
  { label: 'Prozessplanung und Qualitätssicherung', id: 'Prozessplanung und Qualitätssicherung' },
  { label: 'Recht', id: 'Recht' },
  { label: 'Vertrieb und Handel', id: 'Vertrieb und Handel' },
  { label: 'Sonstige Tätigkeitsfelder', id: 'Sonstige Tätigkeitsfelder' },
];

const seniorityType = [
  'Keine',
  'Student/in o. Praktikant/in',
  'Berufseinsteiger/in',
  'Mit Berufserfahrung',
  'Manager/in (mit und ohne Personalverantwortung)',
  'Direktor/in (Bereichsleiter/in, VP, SVP, etc.)',
  'Geschäftsführer/in (GF, CEO, etc.)',
  'Im Ruhestand',
];

const branchType = [
  { label: 'Keine', id: 'Keine' },
  { label: 'Agrarwirtschaft', id: 'Agrarwirtschaft' },
  { label: 'Architektur und Bauwesen', id: 'Architektur und Bauwesen' },
  { label: 'Automobil und Fahrzeugbau', id: 'Automobil und Fahrzeugbau' },
  { label: 'Banken und Finanzdienstleistungen', id: 'Banken und Finanzdienstleistungen' },
  { label: 'Bau', id: 'Bau' },
  { label: 'Beratung und Consulting', id: 'Beratung und Consulting' },
  { label: 'Chemie & Rohstoffe', id: 'Chemie & Rohstoffe' },
  { label: 'E-Commerce & Versandhandel', id: 'E-Commerce & Versandhandel' },
  { label: 'Energie, Wasser und Umwelt', id: 'Energie, Wasser und Umwelt' },
  { label: 'Erziehung, Bildung und Wissenschaft', id: 'Erziehung, Bildung und Wissenschaft' },
  { label: 'Finanzen, Versicherungen & Immobilien', id: 'Finanzen, Versicherungen & Immobilien' },
  { label: 'Flug- und Raumfahrttechnik', id: 'Flug- und Raumfahrttechnik' },
  { label: 'Gesundheit und Soziales', id: 'Gesundheit und Soziales' },
  { label: 'Holz- und Forstwirtschaft', id: 'Holz- und Forstwirtschaft' },
  { label: 'Immobilien', id: 'Immobilien' },
  { label: 'Industrie und Maschinenbau', id: 'Industrie und Maschinenbau' },
  { label: 'Internet und Informationstechnologie', id: 'Internet und Informationstechnologie' },
  { label: 'Konsumgüter und Handel', id: 'Konsumgüter und Handel' },
  { label: 'Kunst, Kultur und Sport', id: 'Kunst, Kultur und Sport' },
  { label: 'Marketing, PR und Design', id: 'Marketing, PR und Design' },
  { label: 'Medien und Verlage', id: 'Medien und Verlage' },
  { label: 'Metall & Elektronik', id: 'Metall & Elektronik' },
  {
    label: 'Öffentlicher Dienst, Verbände und Einrichtungen',
    id: 'Öffentlicher Dienst, Verbände und Einrichtungen',
  },
  { label: 'Personaldienstleistungen', id: 'Personaldienstleistungen' },
  { label: 'Pharma- und Medizintechnik', id: 'Pharma- und Medizintechnik' },
  { label: 'Technik & Telekommunikation', id: 'Technik & Telekommunikation' },
  { label: 'Tourismus und Gastronomie', id: 'Tourismus und Gastronomie' },
  { label: 'Transport, Verkehr und Logistik', id: 'Transport, Verkehr und Logistik' },
  { label: 'Versicherungen', id: 'Versicherungen' },
  { label: 'Verwaltung & Verteidigung', id: 'Verwaltung & Verteidigung' },
  { label: 'Wirtschaftsprüfung, Steuern und Recht', id: 'Wirtschaftsprüfung, Steuern und Recht' },
  { label: 'Sonstige Branchen', id: 'Sonstige Branchen' },
];

const specialityType = {
  'Architektur und Bauwesen': [
    { label: 'Architektur' },
    { label: 'Baugewerbe' },
    { label: 'Bauingenieurwesen' },
    { label: 'Baustoffe' },
    { label: 'Garten- und Landschaftsbau' },
  ],
  'Automobil und Fahrzeugbau': [
    { label: 'Automobile und Zweiräder' },
    { label: 'Fahrzeugvermietung' },
    { label: 'Luft- und Raumfahrtzeugbau' },
    { label: 'Schienenfahrzeugbau' },
    { label: 'Schiffbau' },
    { label: 'Verkehrstechnik' },
  ],
  'Banken und Finanzdienstleistungen': [
    { label: 'Bankwesen' },
    { label: 'Finanzdienstleistungen' },
    { label: 'Investmentbanken' },
    { label: 'Risikokapital und Private Equity' },
  ],
  'Beratung und Consulting': [
    { label: 'Managementberatung' },
    { label: 'IT-Beratung' },
    { label: 'Ingenieurdienstleistungen' },
    { label: 'Sonstige Beratung' },
  ],
  'Energie, Wasser und Umwelt': [
    { label: 'Abfälle und Recycling' },
    { label: 'Energiewirtschaft' },
    { label: 'Erneuerbare Energien' },
    { label: 'Umweltschutz' },
    { label: 'Wasserversorgung und -entsorgung' },
  ],
  'Erziehung, Bildung und Wissenschaft': [
    { label: 'Coaching und Fortbildung' },
    { label: 'E-Learning' },
    { label: 'Fach- und Hochschulen' },
    { label: 'Forschung' },
    { label: 'Kinderbetreuung' },
    { label: 'Schulen und Kindergärten' },
  ],
  'Gesundheit und Soziales': [
    { label: 'Alternative Medizin' },
    { label: 'Arztpraxen' },
    { label: 'Krankenhäuser' },
    { label: 'Medizinische Dienste' },
    { label: 'Pflegeberufe' },
    { label: 'Pharmazie' },
    { label: 'Psychologie und Psychotherapie' },
    { label: 'Sozialwesen' },
    { label: 'Tiermedizin' },
  ],
  'Holz- und Forstwirtschaft': [{ label: 'Holzhandel' }, { label: 'Holzindustrie' }, { label: 'Holzhandwerk' }],
  Immobilien: [{ label: 'Facility Management' }, { label: 'Immobilienvermittlung' }, { label: 'Immobilienverwaltung' }],
  'Industrie und Maschinenbau': [
    { label: 'Bio- und Nanotechnologie' },
    { label: 'Chemie' },
    { label: 'Druck' },
    { label: 'Elektrotechnik' },
    { label: 'Halbleiter und elektronische Bauteile' },
    { label: 'Kunststoff und Gummiwaren' },
    { label: 'Maschinenbau und Betriebstechnik' },
    { label: 'Mess- und Regeltechnik' },
    { label: 'Metallindustrie und -verarbeitung' },
    { label: 'Mineralölverarbeitung' },
    { label: 'Optische und fotografische Geräte' },
    { label: 'Rüstung' },
    { label: 'Verbundwerkstoffe' },
  ],
  'Internet und Informationstechnologie': [
    { label: 'Computer-Hardware' },
    { label: 'Computernetzwerke' },
    { label: 'Computerspiele' },
    { label: 'IT-Dienstleister' },
    { label: 'IT-Sicherheit' },
    { label: 'Internet und Onlinemedien' },
    { label: 'Software' },
    { label: 'Unterhaltungselektronik' },
  ],
  'Konsumgüter und Handel': [
    { label: 'Einzelhandel' },
    { label: 'Getränke' },
    { label: 'Glas und Keramik' },
    { label: 'Großhandel' },
    { label: 'Import und Export' },
    { label: 'Kosmetik und Körperflege' },
    { label: 'Lebensmittel' },
    { label: 'Luxusgüter und Schmuck' },
    { label: 'Möbel und Holzwaren' },
    { label: 'Mode und Textilien' },
    { label: 'Papierwaren' },
    { label: 'Tabakwaren' },
  ],
  'Kunst, Kultur und Sport': [
    { label: 'Bibliotheken' },
    { label: 'Darstellende Kunst' },
    { label: 'Fotografie' },
    { label: 'Kunst und Kunsthandwerk' },
    { label: 'Museen und Kultureinrichtungen' },
    { label: 'Musik' },
    { label: 'Sportler, Veranstalter und Verbände' },
    { label: 'Sportvereine, Sportanlagen und Fitnesszentren' },
  ],
  'Marketing, PR und Design': [
    { label: 'Design und Grafik' },
    { label: 'Marketing und Werbung' },
    { label: 'Markt- und Meinungsforschung' },
    { label: 'Messe, Ausstellungen und Kongresse' },
    { label: 'Öffentlichkeitsarbeit und Kommunikation' },
  ],
  'Medien und Verlage': [
    { label: 'Film und Musik' },
    { label: 'Informationsdienste' },
    { label: 'Journalismus' },
    { label: 'Rundfunk und Fernsehen' },
    { label: 'Text und Lektorat' },
    { label: 'Übersetzen und Dolmetschen' },
    { label: 'Verlagswesen' },
  ],
  'Öffentlicher Dienst, Verbände und Einrichtungen': [
    { label: 'Gemeinnützige Einrichtungen und Vereine' },
    { label: 'Internationale Angelegenheiten' },
    { label: 'Öffentliche Verwaltung' },
    { label: 'Politik und Verbände' },
    { label: 'Religiöse Einrichtungen' },
    { label: 'Verteidigung, Justiz und Polizei' },
  ],
  Personaldienstleistungen: [
    { label: 'Outsourcing und Offshoring' },
    { label: 'Personaldienstleistungen und -beratung' },
  ],
  'Pharma- und Medizintechnik': [{ label: 'Medizintechnik' }, { label: 'Pharmazeutische Produkte und Arzneimittel' }],
  Telekommunikation: [],
  'Tourismus und Gastronomie': [
    { label: 'Gastronomie' },
    { label: 'Hotelgewerbe' },
    { label: 'Reisebüros und -veranstalter' },
    { label: 'Vergnügungsparks und Freizeiteinrichtungen' },
  ],
  'Transport, Verkehr und Logistik': [
    { label: 'Bahnverkehr' },
    { label: 'Lagerhaltung' },
    { label: 'Luftverkehr' },
    { label: 'Personenverkehr' },
    { label: 'Post und Spedition' },
    { label: 'Schifffahrt' },
  ],
  Versicherungen: [],
  'Wirtschaftsprüfung, Steuern und Recht': [
    { label: 'Notariat' },
    { label: 'Rechtsberatung mit Notariat' },
    { label: 'Rechtsberatung ohne Notariat' },
    { label: 'Steuerberatung' },
    { label: 'Wirtschaftsprüfung' },
  ],
  'Sonstige Branchen': [
    { label: 'Bergbau und Metalle' },
    { label: 'Fischerei' },
    { label: 'Jagd' },
    { label: 'Geologie' },
    { label: 'Landwirtschaft' },
    { label: 'Sicherheit und Ermittlungen' },
    { label: 'Sonstige Dienstleistungen' },
    { label: 'Spiel-, Wett- und Lotteriewesen' },
  ],
  'Metall & Elektronik': [
    'Elektrotechnik und Elektroindustrie',
    'Maschinen- und Anlagenbau',
    'Metallverarbeitung',
    'Feinmechanik, Optik, Uhren',
    'Ziehereien und Kaltwalzwerke',
    'Nichteisen-Metallerzeugung',
    'Luft- und Raumfahrtindustrie',
    'Gießereien',
    'Schienenfahrzeugbau',
    'Schiffbau und Meerestechnik',
  ],
};

const educationLevel = [
  { label: 'Keine Angabe', id: 'Keine Angabe' },
  { label: 'Bachelor', id: 'Bachelor' },
  { label: 'Diplom', id: 'Diplom' },
  { label: 'Erstes Staatsexamen', id: 'Erstes Staatsexamen' },
  { label: 'Magister', id: 'Magister' },
  { label: 'Master', id: 'Master' },
  { label: 'Promotion', id: 'Promotion' },
  { label: 'Zweites Staatsexamen', id: 'Zweites Staatsexamen' },
  { label: 'Drittes Staatsexamen', id: 'Drittes Staatsexamen' },
  { label: 'Approbation', id: 'Approbation' },
  { label: 'Abgeschlossene Ausbildung', id: 'Abgeschlossene Ausbildung' },
];

export default {
  geschlechtObj,
  corpsFunction,
  corpsStatuses,
  employmentType,
  activityType,
  seniorityType,
  branchType,
  specialityType,
  educationLevel,
};
